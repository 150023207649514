import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import { CountDownClock } from './index'
import Create from '@material-ui/icons/Create'
import Snackbar from '@material-ui/core/Snackbar'

const styles = theme => ({
    cardBody: {
        padding: '10px',
        backgroundColor: '#fafafa',
    },
    BoldToLighter: {
        fontWeight: '500'
    },
    cardText: {
        color: theme.palette.common.black,
        marginTop: 'none',
        textAlign: 'left'
    },
    NunitoBold: {
        fontWeight: '400',
        letterSpacing: '1px',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    NunitoLight: {
        fontFamily: 'Nunito Sans, sans-serif',
        fontWeight: '400'
    },
    checkboxAndButton: {
        marginRight: '5px',
        background: 'none',
        justifyContent: 'left',
        height: '100%',
        width: 'auto'
    }
})

class ItemMobile extends React.Component {

    state = {
        viewExpiry: false,
        isSelected: true,
        dateState: '',
        isItemExpired: false,
        openSnack: false
    }

    static propTypes = {
        imageSrc: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        listPrice: PropTypes.number.isRequired,
        bidPrice: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        type: PropTypes.number.isRequired,
        date: PropTypes.number,
        productURL: PropTypes.string.isRequired,
        currency: PropTypes.string,
        currencySign: PropTypes.string,
        variantName: PropTypes.string
    }

    componentDidMount() {
        if (this.props.variantName !== "N/A") {
            this.setState({variantName: this.props.variantName})
        }
        this.setState({dateState: this.props.date})
    }

    handleChange = name => event => {
        const { id, onToggleItem } = this.props;
        onToggleItem(id);
        this.setState({ [name]: event.target.checked });
            this.setState({openSnack: true})
    };

    onItemExpiredStatus = expired => {
        console.log(expired)
        this.setState({ isItemExpired: expired })
        if (expired) {
            this.setState({ isSelected: false })
        }
    }

    viewExpiry() {
        this.setState({viewExpiry: !this.state.viewExpiry})
    }

    handleCloseSnack = () => {
         this.setState({ openSnack: false, viewExpiry: false })
    }

  render () {

      const { imageSrc, name, bidPrice, listPrice, classes, type, productURL, currencySign } = this.props
      const { viewExpiry, isSelected, dateState, openSnack, variantName } = this.state

    return (
        <div>
        <Paper style={{boxShadow: 'none'}}>
            <div>
                <Grid container spacing={0} direction="column">
                    <Grid item xs={12} style={{background: `url(${imageSrc}) no-repeat center center`, height: '265px', backgroundSize: 'cover'}}>
                    </Grid>
                    <Grid item xs={12} className={classes.cardBody}>
                        <Grid container spacing={0} direction="row">
                            <Grid item xs={6} style={{textAlign: 'left'}}>
                                { type === 1 || type === 2 ?
                                    <IconButton color="primary" className={classes.checkboxAndButton} href={productURL} target="_blank">
                                        <Create />
                                    </IconButton>
                                : null }
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body1" className={classNames(classes.cardText, classes.NunitoBold)}>
                                    {name} {variantName ? <div style={{display: "inline"}}> &middot; </div> : null} {variantName}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className={classNames(classes.cardText, classes.NunitoLight)}>
                                <Typography variant="body1" gutterBottom style={{marginTop: '0px', fontFamily: 'Nunito Sans, sans-serif'}}>
                                    List Price <b className={classes.BoldToLighter}> {currencySign}{listPrice} </b> | Your Offer <b className={classes.BoldToLighter}> {currencySign}{bidPrice}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Paper>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnack}
          onClose={this.handleCloseSnack}
          autoHideDuration={3000}
          message={isSelected ? <span> Added to checkout </span> : <span> Removed from checkout </span>
          }
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={viewExpiry}
          onClose={this.handleCloseSnack}
          autoHideDuration={8000}
          message={
              <Grid style={{textAlign: 'center'}} container direction="row" alignItems="center">
                  <Grid item>
                    <Typography style={{color: 'white'}} variant="body1">
                        Your offer for this item expires in
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CountDownClock date={dateState} onItemExpired={this.onItemExpiredStatus}/>
                  </Grid>
              </Grid>
          }
        />
        </div>
    )
  }
}

export default withStyles(styles)(ItemMobile)
