import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ItemDesktop from './itemDesktop'
import ItemMobile from './itemMobile'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import classNames from 'classnames'
import withSizes from 'react-sizes'
import CircularProgress from '@material-ui/core/CircularProgress'
import CardMedia from '@material-ui/core/CardMedia'
import { db } from '../firebase'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import Tooltip from '@material-ui/core/Tooltip'
import { HelpModal } from '../components'

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const styles = theme => ({
    root: {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
      },
      title: {
        color: theme.palette.primary.light,
      },
      titleBar: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      },
    topSection: {
        paddingBottom: theme.spacing.unit * 1,
    },
    progress: {
        margin: theme.spacing.unit * 2,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
    checkoutButton: {
        letterSpacing: '1px',
        fontFamily: 'Nunito Sans, sans-serif',
        color: '#2A6BFF',
        background: '#F6F6F8',
        boxShadow: 'none',
        fontWeight: '600',
    },
    checkoutButtonMobile :{
        maxHeight: '30px',
        fontWeight: '600',
        color: '#2A6BFF',
        background: '#F6F6F8',
        letterSpacing: '1px',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    checkoutButtonInDialog: {
        color: '#2a6bff',
        background: `#f6f6f6`,
    },
    checkoutButtonInDialogMobile: {
        maxHeight: '30px',
        color: '#2a6bff',
        background: `#f6f6f6`,
    },

    nameAndCheckout: {
        flexGrow: 1,
        overflow: 'hidden',
        textAlign: 'left',
    },
    pillCheckout: {
        padding: '10px',
        borderBottom: '1px solid #e9e9e9',
        textAlign: 'center',
        color: 'white',
        margin: theme.spacing.unit * 1,
    },
    media: {
        flexGrow: 1,
        width: 'auto',
        height: '60px',
        MaxWidth: '100px'
    },
    mediaMobile: {
        flexGrow: 1,
        width: 'auto',
        height: '30px'
    },
    text: {
        color: 'black',
        fontFamily: 'Nunito Sans, sans-serif',
        letterSpacing: '1px',
    },
    textInDialogName: {
        color: 'black',
        fontFamily: 'Nunito Sans, sans-serif',
        letterSpacing: '1px',
        textAlign: 'left',
        margin: '0px'
    },
    titleTextMobile: {
        fontFamily: 'Nunito Sans, sans-serif',
        letterSpacing: '1px',
        fontWeight: '500',
        color: 'black',
        fontSize: '1em'
    },
    titleText: {
        fontSize: '2em',
        color: 'black',
        fontFamily: 'Nunito Sans, sans-serif',
        letterSpacing: '1px',
        fontWeight: '500'
    },
    titleHeader: {
        color: 'black',
        textAlign: 'center',
        fontFamily: 'Nunito Sans, sans-serif',
        letterSpacing: '1px',
        fontSize: '1.5rem',
        fontWeight: '500'
    },
    BoldToLighter: {
        fontWeight: '500'
    },
    titleSubheader: {
        color: '#7a7a7a',
        textAlign: 'center',
        fontFamily: 'Nunito Sans, sans-serif',
        letterSpacing: '1px',
    },
    totalCartPrice: {
        border: '1px solid #cccccc; padding: 3px 8px; border-radius: 5px'
    },

})

const renderLeftArrow = () => <i className="fa fa-caret-left" />;
const renderRightArrow = () => <i className="fa fa-caret-right" />;

class Store extends React.Component {

    state = {
        items: [],
        checkoutDialogOpen: false,
        checkoutLoading: false,
        failedToCreateOrder: false,
        openSnack: false,
        checkoutItems: [],
        checkoutUrl: '',
        newWindowBlocked: false,
        showCantCheckoutMessage: false,
        showItemsChanged: false,
        changedStoresExpired: false,
        isButtonDisabled: false,
        cartItems: [],
        addCartButton: false,
        totalCartPrice: 0,
        discountPrice: 0,
        storeCurrency: "$",
        isPopUpBlocked: false
    }

    static propTypes = {
        userName: PropTypes.string,
        userID: PropTypes.string,
        type: PropTypes.string.isRequired,

        storeDetails: PropTypes.shape({
            name: PropTypes.string.isRequired,
            storeNotCamel: PropTypes.string.isRequired,
            logo: PropTypes.string.isRequired,
            storeItems: PropTypes.array
        }).isRequired
    }

    onToggleItemStatus = itemID => {
        const { items } = this.state
        var tempArray = items
        const inxOfItem = items.indexOf(itemID)

        if (inxOfItem >= 0) {
            tempArray.splice(inxOfItem, 1)
        } else {
            tempArray.push(itemID)
        }
        this.setState({ items: tempArray })
    }

    // BUILDS MOBILE OR DESKTOP ITEMS IN DOM
    buildResults = () => {

        const { isMobile, type, storeDetails } = this.props
        const storeItems = storeDetails.storeItems

        if (_.size(storeItems) === 0) {
            return {}
        }
        let results
        // since we access items of potentially null object, need to check if exists
        if (storeItems)
        results =
        _.map(storeItems, (item, i) => (
            <div>
            {isMobile ? (
                <ItemMobile
                currency={item.currency}
                currencySign={item.currencySign || "$"}
                imageSrc={item.image}
                name={item.productName}
                listPrice={item.listPrice}
                type={type}
                bidPrice={item.bidPrice}
                variantName={item.variantName}
                id={i}
                productURL={item.productUrl}
                history={this.props.history}
                totalOrderPrice={item.totalOrderPrice}
                date={item.approvedDate}
                onToggleItem={this.onToggleItemStatus}
                />
            ) : (
                <ItemDesktop
                currency={item.currency}
                currencySign={item.currencySign || "$"}
                imageSrc={item.image}
                name={item.productName}
                variantName={item.variantName}
                listPrice={item.listPrice}
                type={type}
                totalOrderPrice={item.totalOrderPrice}
                bidPrice={item.bidPrice}
                id={i}
                productURL={item.productUrl}
                history={this.props.history}
                date={item.approvedDate}
                onToggleItem={this.onToggleItemStatus}
                />
            )}
            </div>
        ))
        return results
    }

    componentDidMount() {

        const { storeDetails } = this.props
        const storeItems = storeDetails.storeItems
        let tempList = []
        for (let i in storeItems) {
            tempList.push(Number(i))
        }
        this.setState({items: tempList})
    }

    // POPUP TO GET ITEMS SITTING IN CART
    addCartItems = () => {
        var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : this.props.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : this.props.height;

        var left = ((width / 2) - (500 / 2)) + dualScreenLeft;
        var top = ((height / 2) - (700 / 2)) + dualScreenTop;

        var addCartModal
        db.getStoreURL(this.props.storeDetails.name).then(storeURL => {

            const finalURL = storeURL + "?r=oaklas"

            addCartModal = window.open(finalURL, "Oaklas", 'toolbar = no, status = no beforeShow, width=500, height=700, top=' + top + ', left=' + left)

            // pop up blocked
            if (addCartModal == null || typeof(addCartModal) == 'undefined') {
                alert('Please disable your pop-up blocker and click the "Add items from cart" button again.');
            } else {
                addCartModal.focus();
            }
        })
        .catch(e => console.log(e))

        // EVENT LISTENER ON MODAL
        var myClick = () => {
            var handler = event => {
                if (event.data === "closeModal") {
                    window.removeEventListener('message', handler);
                    addCartModal.close()
                }

                if (event.data.items) {

                    const tempCartList = []
                    const tempCheckoutList = this.state.checkoutItems
                    let tempTotalCartPrice = this.state.totalCartPrice

                    for (let item of event.data.items) {

                        const tempObject = {
                            productID: Number(item.product_id),
                            productName: item.product_title,
                            variantName: item.variant_title,
                            variantID: item.variant_id,
                            quantity: Number(item.quantity),
                            listPrice: Number(item.price),
                            image: item.image
                        }


                        let priceAsString = item.price.toString()
                        const numberWithoutDec = Number(priceAsString.slice(0, priceAsString.length-2))
                        tempTotalCartPrice += numberWithoutDec * Number(item.quantity)

                        let listPrice = item.price.toString()
                        listPrice = listPrice.slice(0, (listPrice.length-2))
                        tempObject.listPrice = listPrice

                        if (!item.variant_title) {
                            tempObject.variantName = ""
                        }
                        // FINDS AND REMOVES SET DUPLICATES IN CART AND IN USER DASH
                        if (this.state.checkoutItems.some(e => e.variantID === item.variant_id && e.productID === item.product_id)) {
                            if (Number(item.quantity) > 1) {
                                var index = _.findIndex(tempCheckoutList, {variantID: item.variant_id, productID: item.product_id})
                                if (index > -1) {
                                    tempCheckoutList[index].quantity = tempObject.quantity - 1
                                }
                            }
                            tempTotalCartPrice -= numberWithoutDec
                        } else {
                            tempCartList.push(tempObject)
                        }

                    }
                    this.setState({checkoutItems: tempCheckoutList.concat(tempCartList), addCartButton: true, totalCartPrice: tempTotalCartPrice})

                    // CLOSES EVENT LISTENER
                    window.removeEventListener('message', handler);
                    addCartModal.close()
                }
            };

            return handler;
        };

        window.addEventListener('message', myClick());

    }
    // USED TO GENERATE PARAMS FOR CF
    parameterizeArray(key, arr) {
        arr = arr.map(encodeURIComponent)
        return key+'[]=' + arr.join('&'+key+'[]=')
    }

    // GENERATES DRAFT ORDER
    CheckoutClicked = () => {

        const { items, checkoutItems } = this.state
        // const { storeDetails } = this.props
        this.setState({isButtonDisabled: true, checkoutLoading: true})
        var productListForCheckout = []
        var varientListForCheckout = []
        var quantityListForCheckout = []

        // ADD BACK IN IF YOU WANT TO STOP USERS FROM CONSTANTLY CHECKING OUT
        /*
        var uid = db.getUserID()
        let cfDate = 'https://us-central1-oaklas-user.cloudfunctions.net/setAndCheckCheckOutDate?storeName=' + storeDetails.name + '&uid=' + uid

        fetch(cfDate)
        .then(resp => {return resp.json()})
        .then(response => {
            if (response.canCheckout === false) {
                this.setState({showCantCheckoutMessage: true, checkoutDialogOpen: false})
                return false
            }
        */
        if (/* response.canCheckout */ items.length > 0) {

            for (let curItem of checkoutItems) {

                productListForCheckout.push(curItem.productID)
                varientListForCheckout.push(curItem.variantID)
                quantityListForCheckout.push(curItem.quantity)

            }
            // CALLS CF TO GENERATE DRAFT ORDER AND OPENS WINDOW IF CF RETURN APPROVES
            if (productListForCheckout.length > 0) {
                const product = this.parameterizeArray("product", productListForCheckout)
                const variant = this.parameterizeArray("variant", varientListForCheckout)
                const quantity = this.parameterizeArray("quantity", quantityListForCheckout)
                const uid = db.getUserID()
                let cf = 'https://us-central1-oaklas-user.cloudfunctions.net/createDraftOrder?storeName=' + this.props.storeDetails.name + '&' + product + '&' + variant + '&uid=' + uid + '&' + quantity
                fetch(cf)
                .then(response => {
                    if (response) {
                        return response.json()
                    } else {
                        return false
                    }
                }).then(data => {
                    if (data && data.url === 'fail') {
                        this.setState({failedToCreateOrder: true, checkoutLoading: false})
                    } else {
                        if (data) {
                            const newWin = window.open(data.url,'_newtab')
                            if (!newWin) {
                                this.setState({checkoutLoading: false, newWindowBlocked: true, curOrder: data.url, isPopUpBlocked: true, failedToCreateOrder: false})
                            } else {
                                this.setState({checkoutDialogOpen: false, checkoutLoading: false, curOrder: data.url})
                            }
                        }
                    }
                })
                .catch((e) => {
                    console.log(e)
                    this.handleCloseCancel()
                })
            }
        }

    }

    // Reset all checkout state variables for store
    handleCloseCancel = () => {
        this.setState({checkoutDialogOpen: false, failedToCreateOrder: false, checkoutLoading: false,
            isButtonDisabled: false,
            showCantCheckoutMessage: false, showItemsChanged: false,
            changedStoresExpired: false, checkoutButtonLoading: false
        })
    }

    handleCloseSnack = () => {
        this.setState({ openSnack: false })
    }
    


  render() {

    const { classes, type, storeDetails, curWidth, isMobile } = this.props
    const { checkoutDialogOpen, checkoutLoading, failedToCreateOrder, openSnack, checkoutItems, newWindowBlocked,
            curOrder, showCantCheckoutMessage, showItemsChanged, changedStoresExpired, checkoutButtonLoading, addCartButton,
            totalCartPrice, discountPrice, storeCurrency } = this.state

    const results = this.buildResults()

    return (
        <div>

        <HelpModal cantCheckoutMessage={showCantCheckoutMessage} itemsChanged={showItemsChanged} changedStoresExpired={changedStoresExpired} handleClose={() => {this.handleCloseCancel()}}/>

        <Grid className={classes.topSection} container alignItems="center" justify="space-between" direction="row">
            <Grid item className={classes.nameAndCheckout}>
                {storeDetails.logo !== "empty" ? (
                    <div>
                        {isMobile ?
                            <CardMedia
                              component="img"
                              className={classes.mediaMobile}
                              width="auto"
                              image={storeDetails.logo}
                            />
                        :
                        <CardMedia
                          component="img"
                          className={classes.media}
                          width="auto"
                          image={storeDetails.logo}
                        //   style={{backgroundColor:'red'}}
                        />
                        }

                    </div>
                ) : (
                    <Typography className={isMobile ? classes.titleTextMobile : classes.titleText}>
                        {storeDetails.storeNotCamel}
                    </Typography>
                ) }
            </Grid>
            <Grid item>
                {type === 0 ?
                    <div>
                    { checkoutButtonLoading ?
                            <CircularProgress className={classes.progress} size={20} color="secondary"/>
                        :
                        null
                    }
                    </div>
                : null }
            </Grid>
        </Grid>

          <Paper className={classes.root} elevation={0} style={{height: '380px', borderRadius:'0px'}}>
              <Grid className={classes.gridList} container spacing={0} justify="center" direction="row">
              { _.size(results) > 0 ?
                  (
                      <Grid item xs={12}>
                      <GridList className={classes.gridList} container spacing={curWidth} direction="row">
                      {results && _.map(results, (item, i) => (
                          <GridListTile item xs={12} key={i} style={{margin: 'theme.spacing.unit * 2,', height: '380px', width:'208px'}} >
                                {/* {item} */}
                                { console.log(item)}
                                <img src={item.props.children.props.imageSrc} style={{objectFit:'cover', height: '262px', width:'208px', marginBottom:'-5px'}} alt="productImg" />
                                <div style={{border:'1px solid #F6F6F8', fontFamily: 'Nunito Sans, sans-serif', textAlign:'left', fontSize:'0.8em', paddingLeft:'4%'}}>
                                    <span style={{fontSize:'1em', fontWeight:'700'}}>{item.props.children.props.name}</span><br/><span>{item.props.children.props.variantName}</span><br/>
                                    <span>List price {item.props.children.props.currencySign}{item.props.children.props.listPrice}</span><br/>
                                    <span style={{color:'#2A6BFF'}}>Your Offer {item.props.children.props.currencySign}{item.props.children.props.bidPrice}</span>
                                </div>
                          </GridListTile>
                        
                      ))}
                      </GridList>
                      </Grid>
                  )
                  : null
              }
              </Grid>
            </Paper>

          <Dialog
              fullWidth={true}
              open={checkoutDialogOpen}
              onClose={this.checkoutDialogOpen}
              >
              { !failedToCreateOrder && !checkoutLoading && !newWindowBlocked ?
                <DialogTitle className={classes.dialogSecondTitle}>
                    <div>
                        <Typography className={classes.titleHeader} variant="body1">Proceed to checkout</Typography>
                        <Typography className={classes.titleSubheader} variant="body1">with these items from {storeDetails.storeNotCamel}</Typography>
                    </div>
                </DialogTitle>
              : checkoutLoading ?
              <DialogTitle className={classes.dialogSecondTitle}>
                        <Typography className={classes.titleHeader} variant="body1">Generating order, won't be a moment</Typography>
                    </DialogTitle>
              : newWindowBlocked ?
                  <DialogTitle className={classes.dialogSecondTitle}>
                        <Typography className={classes.titleSubheader} variant="body1">Pop ups are blocked</Typography>
                  </DialogTitle>
              : null }

              <DialogContent>
                  { !failedToCreateOrder && !checkoutLoading && !newWindowBlocked ? (
                      <div>
                      <Grid container alignItems="center" justify="space-between" direction="row">
                          <Grid item>
                                <Typography className={classes.totalCartPrice} variant="body1" ><b className={classes.BoldToLighter}>{storeCurrency}{totalCartPrice}</b> Cart Total </Typography>
                                <Typography style={{textAlign: 'center', paddingTop: '5px'}} variant="body1">You save <b className={classes.BoldToLighter}>{storeCurrency}{discountPrice}</b></Typography>
                          </Grid>
                          <Grid item>
                            <Tooltip placement="bottom" title="Add items sitting in your cart to this order">
                                <div>
                                    <Button disabled={addCartButton} size="small" className={isMobile ? classes.checkoutButtonInDialogMobile : classes.checkoutButtonInDialog}
                                        onClick={this.addCartItems}>
                                        Add Items From Cart
                                    </Button>
                                </div>
                            </Tooltip>
                        </Grid>
                     </Grid>
                       <div style={{paddingTop: '10px'}}>
                          {checkoutItems && _.map(checkoutItems, (item, i) => (
                              <div className={classNames(classes.pillCheckout, classes.textInDialogName)} key={i}>

                                    <Grid container alignItems="stretch" justify="flex-start" direction="row">
                                        <Grid item style={{background: `url(${item.image}) no-repeat center center`, width: '70px', backgroundSize: 'cover', borderRadius: '5px', marginRight: '5px'}}>
                                        </Grid>
                                        <Grid>
                                            <Grid container alignItems="center" justify="flex-start" direction="row">
                                                <Grid item>
                                                      <Typography variant="body1"> <b className={classes.BoldToLighter}>{item.productName}</b> {item.variantName}</Typography>
                                                      <Typography variant="body1"> <b className={classes.BoldToLighter}>{item.quantity}</b></Typography>
                                                </Grid>
                                           </Grid>
                                           {!item.bidPrice ?
                                               <Typography variant="body1"> Price <b className={classes.BoldToLighter}>{item.listPrice}</b> | Quantity <b className={classes.BoldToLighter}>{item.quantity}</b> </Typography>
                                           :
                                               <Typography variant="body1"> Price <b className={classes.BoldToLighter}>{storeCurrency}{item.listPrice}</b> | Your Offer <b className={classes.BoldToLighter}>{storeCurrency}{item.bidPrice}</b></Typography>
                                           }
                                        </Grid>
                                    </Grid>
                              </div>
                          ))}
                      </div>
                     </div>
                  ) : checkoutLoading ?
                        <CircularProgress className={classes.progress} size={80} color="secondary"/>
                  : newWindowBlocked ?
                      <div style={{textAlign: 'center'}}>
                            <Typography style={{paddingBottom: "30px"}} className={classes.text} variant="body1">Click the link below or enable popups in your browser</Typography>
                            <a className={classes.text} style={{letterSpacing: '2px'}} onClick={this.handleCloseCancel} rel="noopener noreferrer" href={curOrder} target="_app">Checkout</a>
                      </div>
                  : failedToCreateOrder ?
                      <Typography className={classes.text} variant="body1">Sorry, checkout is currently unavailable. Please try again later or go to the retailers checkout directly. We apologize for the inconvenience</Typography>
                  : null }

                  </DialogContent>

                  <DialogActions>
                        { !failedToCreateOrder && !checkoutLoading && !newWindowBlocked ? (
                            <div>
                                <Button onClick={this.handleCloseCancel} color="secondary" autoFocus>
                                    Cancel
                                </Button>
                                <Button disabled={this.state.isButtonDisabled} onClick={this.CheckoutClicked} color="primary" autoFocus>
                                    Checkout
                                </Button>
                            </div>
                        )
                        : failedToCreateOrder ?
                            <Button onClick={this.handleCloseCancel} color="primary" autoFocus>
                                Okay
                            </Button>
                        : null }
                        </DialogActions>
              </Dialog>
              <Snackbar
                anchorOrigin={{ vertical: isMobile ? 'top' : 'bottom', horizontal: isMobile ? 'center' : 'left' }}
                open={openSnack}
                onClose={this.handleCloseSnack}
                autoHideDuration={3000}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">Please select at least one item to checkout</span>}
              />
          </div>
    )
  }
}

Store.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapSizesToProps = ({ width, height }) => ({
    isMobile: width < 480,
    curWidth: width > 730 ? 24 : 8,
    width: width,
    height: height
})


export default withSizes(mapSizesToProps)(withStyles(styles)(Store))
