import {auth} from './firebase'
import rp from 'request-promise-native'
import Cookie from 'universal-cookie'

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password)

// Sign In
export const doSignInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password)

// Sign out
export const doSignOut = () => auth.signOut()

// Password Reset
export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email)

// Password Change
export const doPasswordUpdate = (password) => auth.currentUser.updatePassword(password)

// Get ID token
export const signin = () => {
    return new Promise(function(resolve, reject) {
        auth.currentUser.getIdToken(true).then(idToken => {
            console.log(idToken)
            var options = {
                crossDomain: true,
                withCredentials: true,
                method: 'POST',
                body: {
                    id: idToken
                },
                json: true
            }
            return rp('https://alveus.serveo.net/oaklas-id/us-central1/app/auth/signin', options)
            // return rp('https://id.oaklas.com/auth/signin', options)
        }).then(res => {
            console.log(res)
            return resolve(res)
        }).catch(e => {
            console.error(e)
            return reject(e)
        })
    })
}

// Get ID token
export const authStatus = () => {
    return new Promise(function(resolve, reject) {
        const cookies = new Cookie();
        console.log(cookies.get('csrfToken'), cookies.get('__session'))
        if (cookies.get('csrfToken')) {
            var options = {
                crossDomain: true,
                withCredentials: true,
                method: 'POST',
                json: true,
                headers: {
                    Authorization: `Bearer ${cookies.get('csrfToken')}`
                }
            }
            console.log(options.headers)
            rp('https://alveus.serveo.net/oaklas-id/us-central1/app/auth/status', options).then(res => {
            // rp('https://id.oaklas.com/auth/status', options).then(res => {
                console.log(res)
                return resolve(res)
            }).catch(e => {
                console.error(e)
                return reject(e)
            })
        } else {
            reject('Not logged in')
        }
    })
}
