import React, { Component } from 'react'
import './App.css'
import { Home, Login, PageNotFound, PageContainer } from './pages'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import 'typeface-nunito'
import AuthorizedRoute from './authorizedRoute'

const theme = createMuiTheme({

    palette: {
        primary: {
          light: '#484848',
          main: '#2A6BFF',
          dark: '#000000',
          contrastText: '#fff'
        },
        fontFamily: 'Nunito Sans, sans-serif'
    },
    overrides: {
        MuiDrawer: {
            // Name of the styleSheet
            paper: {
                // Name of the rule
                backgroundColor: 'black'
            }
        },
        MuiBottomNavigationAction: {
            label: {
                color: 'white'
            }
        }
    }
})

class App extends Component {
  render () {
    return (
        <MuiThemeProvider theme={theme}>
          <Router>
                <div style={{fontFamily: 'Nunito Sans, sans-serif', display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: '100%', overflowX: 'hidden'}}>
                    <Switch>
                      <Route exact path='/login' component={Login} />
                      <AuthorizedRoute exact path='/' component={HomeWrapped} />
                      <Route component={PageNotFound} />
                    </Switch>
                </div>
          </Router>
      </MuiThemeProvider>
    )
  }
}
// const HomeWrapped = ({ location, history }) => <PageContainer page={<Home searchParams={{...location.state }}/>} />
 // stateContext={context.state}/>
const HomeWrapped = () => <PageContainer page={Home} />

export default App
