import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { auth } from '../firebase'
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom'
import  oaklas2019  from '../assets/oaklas2019.png'
import { HelpModal } from './index'
import HelpOutline from '@material-ui/icons/HelpOutline'
import SignOut from '@material-ui/icons/PowerSettingsNew'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import withSizes from 'react-sizes'

const styles = theme => ({
    root: {
        boxShadow: 'none',
        flexGrow: 1,
        background: `#fff`,
    },
    AppBar: {
        flexGrow: 1,
        boxShadow: 'none',
        // borderBottom: '1px solid grey',
        background: `#fff`,
        position: 'fixed'
    }
})

class Navigation extends React.Component {

    state = {
        open: false,
    };

    handleClickOpenDash = () => {
        this.setState({ open: true, isRefresh: false });
    };

    signOut = () => {
        auth.doSignOut()
    }

  render () {
    const { isMobile, classes } = this.props

    return (
      <div className={classes.root}>

        <HelpModal open={this.state.open} isRefresh={this.state.isRules} openDash={false} handleClose={() => this.setState({open: false})}/>

        <AppBar position='sticky' className={classes.AppBar}>
          <Toolbar>
            <Link to='/' style={{flex: 1}}>
            <img src={oaklas2019} style={ isMobile ? {padding: '5px', width: '100px'} : {padding: '5px', width: '150px'}} alt=""/>
            </Link>

            <Tooltip title="Help" style={{fontFamily: 'Nunito Sans, sans-serif'}}>
                <IconButton type='button' style={{color: '#2A6BFF'}} onClick={this.handleClickOpenDash}>
                  <HelpOutline />
                </IconButton>
            </Tooltip>
            <Tooltip title="Sign Out">
                  <IconButton type='button' style={{color: '#2A6BFF'}} onClick={() => this.signOut()}>
                    <SignOut />
                  </IconButton>
            </Tooltip>
          </Toolbar>

        </AppBar>

      </div>
    )
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480
})

export default withRouter(withSizes(mapSizesToProps)(withStyles(styles)(Navigation)))
