import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { db } from '../firebase'
import Snackbar from '@material-ui/core/Snackbar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide';
import withSizes from 'react-sizes'

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    media: {
        width: 'auto',
        height: '70px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
    textParagraph: {
        fontFamily: 'Nunito Sans, sans-serif',
        textAlign: 'left',
        paddingBottom: '20px',
        fontSize: '1rem',
        lineHeight: '1.5',
        fontStretch: 'expanded'
    },
    avatar: {
        margin: 10,
        fontSize: '40px',
        fontFamily: 'Nunito Sans, sans-serif',
        color: '#fff',
        width: 133,
        height: 133,
        background: `linear-gradient(125deg, #2A6BFF 50%, #5A89FF 50%)`,
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        border: '6px solid #ffffff',
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
    }
})

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class HelpModal extends Component {

    state = {
        firstName: '',
        lastName: '',
        saveSuccesOpen: false,
        fillBothOpen: false
    }

    handleClickSnackBothFields = () => {
        this.setState({ fillBothOpen: true })
    }

    handleClickSnackBothFieldsClose = () => {
         this.setState({ fillBothOpen: false })
    }

    handleClickSnackSaveSuccess = () => {
        this.setState({ saveSuccesOpen: true })
    }

    handleCloseSnackSaveSuccess = () => {
         this.setState({ saveSuccesOpen: false })
    }

    handleClose = () => {
        const { firstName, lastName } = this.state
        if (firstName && lastName) {
            // string only contained whitespace (ie. spaces, tabs or line breaks)
            if (firstName.replace(/\s/g, '').length && lastName.replace(/\s/g, '').length) {
               db.updateName(firstName, lastName)
               .then(snapshot => {
                   const userName = firstName + " " + lastName
                   this.handleClickSnackSaveSuccess()
                   this.handleClickSnackSaveSuccess()
                   this.props.handleClose(userName)
               })
            } else {
                this.handleClickSnackBothFields()
            }
        } else {
            this.handleClickSnackBothFields()
        }
    }


    handleCloseCancel = () => {
        this.props.handleClose()
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

  render() {

      const { classes, isMobile, cantCheckoutMessage, changedStoresExpired, itemsChanged  } = this.props
      const { firstName, saveSuccesOpen, fillBothOpen } = this.state

    return (
        <div>
        { this.props.open ?
            <Dialog
                open={this.props.open}
                TransitionComponent={Transition}
                onClose={this.handleCloseCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <br></br>
                    <DialogTitle style={{textAlign: 'center', fontFamily: 'Nunito Sans, sans-serif'}} id="alert-dialog-title">Help</DialogTitle>
                    <DialogContent style={{fontFamily: 'Nunito Sans, sans-serif'}}>
                    <br></br>
                        <DialogContentText id="alert-dialog-img" style={{fontFamily: 'Nunito Sans, sans-serif', color: '#3e3e3e'}}>
                        <b>How do I purchase approved offers?</b> <br/>
                        Once an offer is accepted, payment automatically goes through - this is why you were required to enter your card details before submitting offer. <br/><br/>

                        <b>Can I change/delete my offer?</b> <br/>
                        You can change your offer whilst it is sitting in pending. You can do this by submitting clicking on the product in the ‘pending’ tab. Once an item is accepted, you cannot change your offer. You cannot delete your offer. <br/><br/>

                        <b>Can I resubmit a rejected offer?</b> <br/>
                        You can do so as long as the campaign lasts. This is dependant on how long the retailer is allowing offers to be made on that product. <br/><br/>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseCancel} color="primary" autoFocus style={{fontFamily: 'Nunito Sans, sans-serif'}}>
                            Thanks!
                        </Button>
                    </DialogActions>
                </Dialog>
        :
        this.props.openName ?
        <div>
            <Dialog
                open={this.props.openName}
                onClose={this.handleClose}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Edit Name"}</DialogTitle>
                    <DialogContent>
                    <form className={classes.container} noValidate autoComplete="off">
                            <TextField
                              id="standard-name"
                              label="First Name"
                              className={classes.textField}
                              value={this.state.firstName}
                              onChange={this.handleChange('firstName')}
                              margin="normal"
                            />
                            <TextField
                              id="standard-name"
                              label="Last Name"
                              className={classes.textField}
                              value={this.state.lastName}
                              onChange={this.handleChange('lastName')}
                              margin="normal"
                            />
                    </form>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleCloseCancel} color="secondary" autoFocus>
                            Cancel
                        </Button>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        :
        this.props.isNewUser ?
        //this is the modal we have to change
        <div>
            <Dialog
                open={this.props.isNewUser}
                onClose={this.handleCloseCancel}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <br></br>
                    <DialogTitle style={{textAlign: 'center'}} id="alert-dialog-title">{"Welcome To Your Oaklas Dashboard!"}</DialogTitle>
                    <DialogContent>
                        <Grid container justify='center'>
                            <Grid item xs={11}>
                            <div style={{textAlign: 'center'}}>
                                 <div>
                                 <Grid container justify='center' style={{textAlign: isMobile ? 'left' : 'justify', paddingBottom: '20px'}}>

                                     <div>
                                        <br></br><br></br>
                                        <Typography variant="title" style={{paddingBottom: '20px'}} className={classes.textParagraph} >
                                            <b>Here you can manage all your offers you have made.</b><br/>
                                            All approved offers represent purchases you have already made.<br/>
                                            As soon as an offer is approved, the payment automatically goes through!<br/>
                                            <br/><br/>
                                            <b>You can change any pending offer before it gets accepted.</b><br/>
                                            However, once the retailer accepts you will not be able to retract your offer.<br/>
                                            You cannot cancel a pending offer.
                                        </Typography>
                                    </div>
                                   </Grid>
                                </div>
                             </div>
                            </Grid>
                          </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseCancel} color="primary" autoFocus>
                            Thanks!
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            :
            this.props.openTerms ?
            <Dialog
                open={this.props.openTerms}
                TransitionComponent={Transition}
                onClose={this.handleCloseCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{textAlign: 'center', fontFamily: 'Nunito Sans, sans-serif'}} id="alert-dialog-title">{"TERMS OF USE"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-img" style={{fontFamily: 'Nunito Sans, sans-serif'}}>
                        <b>Welcome to Oaklas! We are excited to have you on board! However, to register to our free service, you will need to agree to our user agreement. This document will specify rules relating to use, rights and more.</b> <br/> <br/>
                        Definitions: <br/> <br/>

                        Non-version: When a user fails to convert an accepted offer. <br/>
                        User: You, the person using the service <br/>
                        We: Oaklas/Us <br/><br/><br/>

                        1. Oaklas Rights <br/><br/>

                        1.1 Oaklas reserves the right to:<br/><br/>

                        1.1.1 Ban, whether permanently or temporarily any person that abuses our service, or at our own discretion;and <br/>
                        1.1.2 Add or deduct offers from any persons offer count at our own discretion; and <br/>
                        1.1.3 Modify/change/delete any offer made by any persons; and <br/>
                        1.1.4 Require users to agree to any revised user agreement; and <br/>
                        1.1.5 Require users to provide additional information; and <br/>
                        1.1.6 Send promotional material to users<br/><br/>

                        1.2 For the purposes of 1.1.1, abuse of service includes, but is not limited to:<br/><br/>

                        1.2.1 Habitual offers with discount of 80%+; <br/>
                        1.2.2 Habitual non-versions amounting to 50%+ of accepted offers; <br/>
                        1.2.3 Users who steal, attempt to steal, copy, attempt to copy, use or attempt to use our intellectual property<br/><br/><br/>


                        2. Intellectual property<br/><br/>

                        2.1 By using the Oaklas service, you agree that all software and ideas are the intellectual property of Oaklas Pty Ltd. <br/>
                        2.2 By using the Oaklas service, you acknowledge that Oaklas owns all content already displayed website or input by users. <br/>
                        2.3 By using the Oaklas service, you agree to allow Oaklas to utilise all user content in accordance with our privacy policy<br/><br/><br/>


                        3. Clauses<br/><br/>

                        3.1 Oaklas does not agree to ensure the sale of any accepted offer <br/>
                        3.2 Oaklas does not agree to ensure the availability of any product at any stage of the offer process <br/>
                        3.3 It is not the responsibility of Oaklas to resolve any and all problems regarding a product purchased through the Oaklas service. Any and all problems with a product are to be resolved between the user and the retailer.<br/><br/><br/>


                        4. Terms of Use<br/><br/>

                        4.1 The user must be at least 18 years of age <br/>
                        4.2 The user must not publicise - through any medium - the price of any offer. <br/>

                        For issues or inquiries contact the Oaklas team at info@oaklas.com<br/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseCancel} color="primary" autoFocus style={{fontFamily: 'Nunito Sans, sans-serif'}}>
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
        :
        this.props.openPrivacy ?
        <Dialog
            open={this.props.openPrivacy}
            TransitionComponent={Transition}
            onClose={this.handleCloseCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{textAlign: 'center',fontFamily: 'Nunito Sans, sans-serif'}} id="alert-dialog-title">{"PRIVACY POLICY"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-img" style={{fontFamily: 'Nunito Sans, sans-serif'}}>
                    <b>By using this service, you agree to the privacy policy found at oaklas.com/privacy</b> <br/> <br/>
                    By using Oaklas, you, the consumer, agree to all terms of service provided below. <br/><br/>

                    <b>Our Intellectual Property</b> <br/>
                    Our website and service are the exclusive property of Oaklas. Information provided by our website are not intended to create legally binding assurances. Any information garnered by you, the consumer from our website is subject to regular change by us - Oaklas.
                    You must not copy, imitate, modify, alter, amend or use without our prior written consent any URLs representing our Website, or any of our content, logos, graphics, icons or other content published on our Website or in our printed media. <br/><br/>


                    <b>Limitation of Liabilities</b> <br/>
                    To the full extent permitted by law: <br/>
                    Oaklas’s liability for all claims arising under or related in any way to this Agreement no matter how arising, and whether in contract, tort (including negligence), or otherwise, will not exceed:(A) $500; or, if that limit is found to be unenforceable; $1,000; or, if that limit is found to be unenforceable; the total value of any affected Order(s); and Oaklas and its related bodies corporate, or any third party providing services on behalf of Oaklas, will not be liable to you for any Consequential Loss arising under or related in any way to this Agreement no matter how arising and whether in contract, tort (including negligence) or otherwise. <br/><br/>Oaklas and its related bodies corporate, or any third party providing services on behalf of Oaklas, and the directors, employees, officers, agents and representatives of them, will not, to the extent permitted by law, be liable for any loss or damage (including any direct, indirect or Consequential Loss or damage) you or any third party may incur from your purchase or use of any goods or services from a Retailer, except to the extent that we cause such loss or damage (in which case, our liability to you is limited as set out in clause 5(a)(i) above). <br/><br/>You acknowledge that any consumer-related claims you may have in respect of the goods or services purchased from a Retailer will be a matter between you and the Retailer. To the extent permitted by law, a party’s liability to the other party under or in connection with this Agreement is reduced to the extent, if any, to which the other party’s acts or omissions cause or contribute to its own loss or damage. <br/><br/>The parties will take all reasonable steps to mitigate any loss incurred by them under this Agreement. <br/><br/>


                    <b>What Oaklas does not assure</b> <br/>

                    Oaklas does not ensure that any offers made through our website will reach the corresponding retailer. <br/>
                    In the occurrence that a offers reaches the corresponding retailer, Oaklas does not ensure a response from the retailer
                    In the occurrence that a retailer responds to a offer, Oaklas does not ensure that: <br/>
                    The offer will be accepted; or The offer will be rejected; or That any transaction will consequently follow <br/><br/>
                    In the occurrence that a retailer wishes to accept a offer, Oaklas does not ensure the materialisation of that transaction and any disputes resulting, whether it has to do with acceptance or price or any other matter is between the consumer and retailer. <br/><br/>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseCancel} color="primary" autoFocus style={{fontFamily: 'Nunito Sans, sans-serif'}}>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        :
        cantCheckoutMessage || changedStoresExpired || itemsChanged ?
        <Dialog
            open={cantCheckoutMessage || changedStoresExpired || itemsChanged}
            TransitionComponent={Transition}
            onClose={this.handleCloseCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                { cantCheckoutMessage ? <DialogTitle style={{textAlign: 'center'}} id="alert-dialog-title">{"You've Recently Checked Out"}</DialogTitle>
                : itemsChanged ? <DialogTitle style={{textAlign: 'center'}} id="alert-dialog-title">{"You have already purchased some of these items"}</DialogTitle>
                : changedStoresExpired ? <DialogTitle style={{textAlign: 'center'}} id="alert-dialog-title">{"Items expired"}</DialogTitle>
                : null}
                <DialogContent>
                    { cantCheckoutMessage ? <DialogContentText>It looks like you have recently checked out. Please wait 5 minutes before you checkout again. </DialogContentText>
                    : itemsChanged ? <DialogContentText>It looks like you've already purchased some of these items. Please unselect them hit 'update page' to continue. </DialogContentText>
                    : changedStoresExpired ? <DialogContentText>Woops. It looks like one of your items has expired. Please unselect it and click update button to continue. </DialogContentText>
                    : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseCancel} color="primary" autoFocus>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        : null }

        <Snackbar
          anchorOrigin={{ vertical: isMobile ?  'top' : 'bottom', horizontal: isMobile ? 'center' : 'left' }}
          open={fillBothOpen}
          onClose={this.handleClickSnackBothFieldsClose}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Please fill in both fields</span>}
        />

        <Snackbar
          anchorOrigin={{ vertical: isMobile ?  'top' : 'bottom', horizontal: isMobile ? 'center' : 'left' }}
          open={saveSuccesOpen}
          onClose={this.handleCloseSnackSaveSuccess}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Welcome {firstName}</span>}
        />

        </div>

    )
  }
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 480
})

export default withSizes(mapSizesToProps)(withStyles(styles)(HelpModal))
