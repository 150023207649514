import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import { HelpModal } from './index'

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: 'none',
        flexGrow: 1,
        borderBottom: '1px solid grey',
        background: `#fff`,
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column'
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    appBar: {
       top: 'auto',
       bottom: 0,
       padding: theme.spacing.unit * 2,
       // borderTop: '1px solid grey',
       background: `#F6F6F8`,
       boxShadow: 'none',
     },
    button: {
        color: '#656565'
    },
    logo: {
        width: '150px',
        height: '100%',
        maxWidth: '100%'
    }
})

class BottomNav extends React.Component {
    state = {
        openTerms: false,
        openPrivacy: false
    }

    handleClickOpenTerms = () => {
        this.setState({ openTerms: true});
    };

    handleClickOpenPrivacy= () => {
        this.setState({ openPrivacy: true});
    };

  render () {
    const { classes } = this.props

    return (

        <AppBar position="sticky" color="primary" className={classes.appBar}>

            <HelpModal openTerms={this.state.openTerms} openPrivacy={this.state.openPrivacy} handleClose={() => this.setState({openPrivacy: false, openTerms: false})}/>

           <Toolbar className={classes.toolbar}>
           <div>
             <Button color="inherit" className={classes.button} onClick={this.handleClickOpenTerms}>
               <b style={{fontWeight: '400', fontFamily: 'Nunito Sans, sans-serif'}}> TERMS OF USE </b>
             </Button>
             <Button color="inherit" className={classes.button} onClick={this.handleClickOpenPrivacy}>
              <b style={{fontWeight: '400', fontFamily: 'Nunito Sans, sans-serif'}}> PRIVACY POLICY </b>
             </Button>
             </div>
             <div>
             <Typography variant="subheading" className={classes.button} style={{fontFamily: 'Nunito Sans, sans-serif', fontWeight: '100'}}>COPYRIGHT &copy; 2018 OAKLAS</Typography>
             </div>
           </Toolbar>
         </AppBar>

    )
  }
}

export default withStyles(styles)(BottomNav)
