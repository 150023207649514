import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import withSizes from 'react-sizes'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        flexGrow: 1,
        padding: theme.spacing.unit * 1,
        paddingLeft: '10px'
    },
    typography: {
        color: 'white'
    }
});

class CountDownClock extends React.Component {

    state = {
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
        loading: true,
        isExpired: false,
        showSeconds: false,
        showHours: true
    }

    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.props.date);

            date ? this.setState(date) : this.stopExpired()
        }, 1000);
    }

    componentWillUnmount() {
        this.stop();
    }

    stop() {
        clearInterval(this.interval);
        this.setState({loading: false})
    }

    stopExpired() {
        clearInterval(this.interval);
        this.setState({loading: false})
        this.setState({isExpired: true})
        const { onItemExpired } = this.props;
        onItemExpired(true);
    }

    calculateCountdown(submittedDate) {

        var curDate = new Date()
        var timeDiff = Math.abs(curDate.getTime() - submittedDate);
        var diff = Math.ceil(timeDiff / (1000));

        // get submittedDate  2 days and find the diff between e
        // let diff = (Date.parse(new Date(submittedDate)) - Date.parse(new Date())) / 1000;

        if (diff > 48 * 60 * 60) return false;

        diff = (48 * 60 * 60) - diff

        const timeLeft = {
            hours: 0,
            min: 0,
            sec: 0,
        };

        // // calculate time difference between now and expected date
        // if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
        //   timeLeft.years = Math.floor(diff / (365.25 * 86400));
        //   diff -= timeLeft.years * 365.25 * 86400;
        // }

        // if (diff >= 86400) { // 24 * 60 * 60
        //   timeLeft.days = Math.floor(diff / 86400);
        //   diff -= timeLeft.days * 86400;
        // }

        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }

        timeLeft.sec = diff;
        if (timeLeft.hours === 0) {
            this.setState({showHours: false})
        }

        if (timeLeft.hours === 0 && timeLeft.min < 11) {
            this.setState({showSeconds: true})
        }
        this.setState({loading: false})
        return timeLeft;
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }


    render() {
        const { classes, isMobile } = this.props;
        const countDown = this.state;

        return (
            <div className={classes.root}>
                {countDown.loading ?
                    <CircularProgress className={classes.typography} size={20} color="secondary"/>
                    :
                    !countDown.isExpired && !countDown.showSeconds && countDown.showHours ?
                    <div>
                        { isMobile ?
                            <Typography className={classes.typography} variant="body1">
                                <b>{countDown.hours}</b>Hours, <b>{countDown.min}</b>Mins
                            </Typography>
                            :
                            <Typography className={classes.typography} variant="body1">
                                <b>{countDown.hours}</b>Hours, <b>{countDown.min}</b>Mins Remaining
                            </Typography>
                        }
                    </div>
                    :
                    !countDown.isExpired && !countDown.showHours && !countDown.showSeconds ?

                    <div>
                        { isMobile ?
                            <Typography className={classes.typography} variant="body1">
                                <b>{countDown.min}</b>Minutes
                            </Typography>
                            :
                            <Typography className={classes.typography} variant="body1">
                                <b>{countDown.min}</b>Minutes Remaining
                            </Typography>
                        }
                    </div>
                    :
                    !countDown.isExpired && countDown.showSeconds ?

                    <div>
                        { isMobile ?
                            <Typography className={classes.typography} variant="body1">
                                <b>{countDown.min}</b>Mins<b>{countDown.sec}</b>Secs
                            </Typography>
                            :
                            <Typography className={classes.typography} variant="body1">
                                <b>{countDown.min}</b>Mins<b>{countDown.sec}</b>Secs Remaining
                            </Typography>
                        }
                    </div>

                    :
                    <Typography className={classes.typography} variant="body1">
                        <b>Expired</b>
                    </Typography>
                }
            </div>
        );
    }
}

CountDownClock.propTypes = {
    date: PropTypes.number.isRequired
};

CountDownClock.defaultProps = {
    date: new Date()
};

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 480
})

export default withSizes(mapSizesToProps)(withStyles(styles)(CountDownClock))
