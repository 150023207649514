import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

const userConfig = {
  apiKey: "AIzaSyB5rSaDR8wATQh7XcppVYpv5A3cILnwjNo",
  authDomain: "oaklas-user.firebaseapp.com",
  databaseURL: "https://oaklas-user.firebaseio.com",
  projectId: "oaklas-user",
  storageBucket: "oaklas-user.appspot.com",
  messagingSenderId: "282663992239"
};

const retailerConfig = {
  apiKey: 'AIzaSyCgiHK5cVxarmTZyPnYPgAooq7f8FQVhM0',
  authDomain: 'oaklas-bookmark-tool.firebaseapp.com',
  databaseURL: 'https://oaklas-bookmark-tool.firebaseio.com',
  projectId: 'oaklas-bookmark-tool',
  storageBucket: 'oaklas-bookmark-tool.appspot.com',
  messagingSenderId: '650641977232'
};

// const config = process.env.NODE_ENV === 'production'
//   ? prodConfig
//   : devConfig
//
// if (!firebase.apps.length) {
//   firebase.initializeApp(config)
// }

const userFB = firebase.initializeApp(userConfig)
const retailerFB = firebase.initializeApp(retailerConfig, 'other')

const retailerDB = retailerFB.database()
const userDB = userFB.database()
const auth = userFB.auth()
const retailerStorage = retailerFB.storage()

export {
  firebase,
  auth,
  retailerDB,
  retailerStorage,
  userDB,
}
