import React, {Component} from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import {firebase, auth} from '../firebase'
import {Redirect} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import oaklas2019 from '../assets/oaklas2019.png'
import firebaseui from 'firebaseui'
import queryString from 'query-string';

class Login extends Component {

    state = {
        isSignedIn: false
    };

    uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                const user = authResult.user;
                auth.signin().then(res => {
                    console.log(res)
                    if (authResult.additionalUserInfo.isNewUser) {
                        if (user.displayName) {
                            let firstName = (user.displayName).substr(0, (user.displayName).indexOf(' '))
                            const lastName = (user.displayName).substr((user.displayName).indexOf(' ') + 1)
                            firebase.database().ref('/users/' + user.uid + "/meta").set({
                                firstName: firstName,
                                lastName: lastName,
                                email: user.email,
                                isNewUser: true
                            }).then(() => {
                            }).catch(console.error)
                        }
                    }
                    if (this.state.urlParams.r) window.location.href = this.state.urlParams.r
                    // else window.location.href = "https://user.oaklas.com"
                }).catch(console.error)
                return false;
            },
            uiShown: function() {}
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        'credentialHelper': firebaseui.auth.CredentialHelper.NONE,
        //signInSuccessUrl: '/' + redirect,
        signInOptions: [// Leave the lines as is for the providers you want to offer your users.
            firebase.auth.EmailAuthProvider.PROVIDER_ID
        ]
    };

    componentWillMount() {
        var urlParams = queryString.parse(this.props.location.search)
        this.setState({urlParams: urlParams})
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(res => {
            return auth.authStatus()
        }).then(res => {
            if (urlParams.r) window.location.href = this.state.urlParams.r
            // else window.location.href = "https://user.oaklas.com"
            console.log(res)
        }).catch(error => {
            console.error(error);
        })
    }

    // Listen to the Firebase Auth state and set the local state.
    // componentDidMount() {
    //     firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(res => {
    //         return auth.authStatus()
    //     }).then(res => {
    //         var urlParams = queryString.parse(this.props.location.search)
    //         if (urlParams.r) window.location.href = urlParams.r
    //         else window.location.href = "https://user.oaklas.com"
    //         console.log(res)
    //     }).catch(error => {
    //         console.error(error);
    //     })
    // }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {

        if (!this.state.isSignedIn) {
            return (<Grid container="container" justify='center' alignItems='center' spacing={0} direction="column">
                <div style={{
                        width: '300px',
                        border: '1px solid #dddddd',
                        borderRadius: '5px',
                        marginTop: '60px',
                        padding: '60px 50px 70px 50px'
                    }}>
                    <a href="https://www.oaklas.com/">
                        <img src={oaklas2019} alt="oaklas" style={{
                                width: '150px',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '40px',
                                marginTop: '-20px'
                            }}/>
                    </a>
                    <h2 style={{
                            textAlign: 'center',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontSize: '1.5em',
                            fontWeight: '400',
                            color: '#3e3e3e',
                            textTransform: 'uppercase',
                            letterSpacing: '1'
                        }}>
                        SIGN IN OR SIGN UP
                    </h2>
                    <p style={{
                            textAlign: 'center',
                            color: '#7f7f7f',
                            fontWeight: '300',
                            marginTop: '-10px',
                            fontSize: '1.2em'
                        }}>
                        to start making offers online
                    </p>
                    <br/><br/><br/>
                    <StyledFirebaseAuth uiCallback={ui => ui.disableAutoSignIn()} uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
                </div>
            </Grid>)
        } else {
            return (<Redirect to={{
                    pathname: this.state.urlParams.r
                }}/>)
        }
    }

}

export default Login
