import React, {Component} from 'react'
import { Navigation, BottomNav } from '../components'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import withSizes from 'react-sizes'
import { Offline } from "react-detect-offline"
import Snackbar from '@material-ui/core/Snackbar'

class PageContainer extends Component {

    static propTypes = {
        page: PropTypes.func.isRequired
    }

    render () {

        const { page: Page, isMobile } = this.props

        return (
            <div>
                <Grid container spacing={0} direction='column'>
                    <Grid item >
                        <Navigation/>
                    </Grid>
                    <Grid item style={{backgroundColor: '#fff', paddingBottom: isMobile ? '50px' : '100px'}}>
                        <div style={{fontFamily: 'Nunito Sans, sans-serif', display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                            <div style={{flex: 1}}>
                            { !isMobile ?
                                <Grid container justify='center'>
                                    <Grid item xs={11}>
                                        <Page/>
                                    </Grid>
                                </Grid>
                            :
                                <Grid container justify='center'>
                                    <Grid item xs={12}>
                                        <Page />
                                    </Grid>
                                </Grid>
                            }
                            </div>
                        </div>
                    </Grid>
                    <Grid item >
                        <BottomNav/>
                    </Grid>
                </Grid>

                <Offline>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={true}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">You're offline, please reconnect</span>}
                    />
                </Offline>
            </div>

        )
    }
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 480
})

export default withSizes(mapSizesToProps)(PageContainer)
