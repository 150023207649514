import React, { Component } from 'react'
import { Store } from '../components'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withSizes from 'react-sizes'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CheckCircle from '@material-ui/icons/CheckCircle'
import HelpIcon from '@material-ui/icons/Help'
import CancelIcon from '@material-ui/icons/Cancel'
import { db, auth } from '../firebase'
import _ from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { HelpModal } from '../components'
import Snackbar from '@material-ui/core/Snackbar'
import Update from '@material-ui/icons/Update'
import grey from '@material-ui/core/colors/grey';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import '../App.css';

const styles = theme => ({
    root: {
        flexGrow: 1,
        fontFamily: 'Nunito Sans, sans-serif',
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    storeRoot: {
        paddingTop: theme.spacing.unit * 4,
    },
    bidInfo: {
        display: 'inline-block',
        fontFamily: 'Nunito Sans, sans-serif',
        paddingRight: theme.spacing.unit * 2,
    },
    bidInfoMobile: {
        display: 'inline-block',
        fontFamily: 'Nunito Sans, sans-serif',
        paddingRight: theme.spacing.unit * 2,
        textAlign: 'center'
    },
    offerCounter: {
        textAlign: 'center',
    },
    button: {
        margin: theme.spacing.unit,
        letterSpacing: '2px',
        background: `linear-gradient(125deg, #2A6BFF 50%, #5A89FF 50%)`,
        fontFamily: 'Nunito Sans, sans-serif',
    },
    buttonUpdate: {
        color: '#2A6BFF'
    },
    nameButton: {
        textAlign: 'left',
        margin: '0',
        padding: '0',
        '&:hover': {
            background: 'transparent'
        }
    },
    nameButtonMobile: {
        textAlign: 'left',
        margin: '0',
        padding: '0',
    },
    text: {
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '1px',
        color: '#999',
        fontFamily: 'Nunito Sans, sans-serif',
        textTransform: 'lowercase'
    },
    progress: {
        color: '#2A6BFF',
        textAlign: 'center',
        margin: theme.spacing.unit * 2,
    },
    itemGrid: {
        flexGrow: 1,
        maxWidth: 1200,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        marginBottom: '0px',
        paddingBottom: '0px'
    },
    logo: {
        textAlign: 'center',
    },
    media: {
        width: '350px',
        height: '280px',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // display: 'block',
    },
    divLogo: {
        paddingTop: '100px'
    },
    titleText: {
        paddingTop: '50px',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    textParagraph: {
        fontFamily: 'Nunito Sans, sans-serif',
        color: grey[500],
    },
    userName: {
        textTransform: 'capitalize',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    avatar: {
        margin: 10,
        fontSize: '12px',
        fontFamily: 'Nunito Sans, sans-serif',
        color: '#2A6BFF',
        width: 133,
        padding: '5px',
        height: 133,
        background: grey[200],
        cursor: 'pointer',
        // boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    avatarSmall: {
        width: 100,
        height: 100,
    },
    avatarQuestionMark: {
        width: 30,
        height: 30,
        float: 'right',
        marginRight: '15px',
        marginTop: '-39px',
        background: '#e7e7e8',
        border: '5px solid #fff',
    },
    offerCountAvatar: {
        fontSize: '50px',
        // marginBottom: '-25px',
        // marginTop: '-7px',
    },
    avatarMobile: {
        margin: 2,
        marginRight: '20px',
        fontSize: '20px',
        fontFamily: 'Nunito Sans, sans-serif',
        color: '#2A6BFF',
        width: 63,
        height: 63,
        background: `#f6f6f8`,
        // boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        // border: '6px solid #ffffff',
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    countObjects: {
        paddingBottom: theme.spacing.unit * 2,
    },
    newOffer: {
        display: 'inline-flex',
        verticalAlign: 'middle'
    },
    card: {
        maxWidth: 345,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
})

// const images = {
//     facebook:{
//       url: 'https://s3-ap-southeast-2.amazonaws.com/oaklas/logos/facebook.png',
//       title: 'facebook',
//       width: '40%',
//     },
//     twitter:{
//       url: 'https://s3-ap-southeast-2.amazonaws.com/oaklas/logos/twitter.png',
//       title: 'twitter',
//       width: '30%',
//     },
//     instagram:{
//       url: 'https://s3-ap-southeast-2.amazonaws.com/oaklas/logos/instagram.png',
//       title: 'instagram',
//       width: '30%',
//     },
// };

class Home extends Component {

    state = {
        data: [],
        type: 1,
        userName: "",
        stores: [],
        userID: "",
        offerCount: -1,
        curPending: 0,
        curApproved: 0,
        curRejected: 0,
        loading: true,
        metaLoading: true,
        noItemsInAnyStore: false,
        openName: false,
        isNewUser: false,
        openSuccess: false,
        upAndDownVal: 0,
        checkForUpdatesClicked: false
    }

    componentDidMount() {
        db.getCurrentUser()
        .then(user => {
            this.setState({userID: user.uid})
            this.checkForUpdates(true)
        })
        auth.signin().then(res => {
            console.log(res)
            return auth.profileTest()
        }).then(res => {

        }).catch(console.error)
    }

    // Used to call CF to trigger updates in user DB
    // didLogin Boolean to show Snackbar only if update button is clicked
    checkForUpdates = (didLogin) => {

        db.getUserMeta()
        .then(snapshot => {

            if (didLogin) {
                const firstName = snapshot.firstName
                const lastName = snapshot.lastName
                const isNewUser = snapshot.isNewUser
                const name = firstName + " " + lastName

                if (isNewUser) {
                    this.setState({nameofferCount: 10, curPending: 0, curApproved: 0, isNewUser: true})
                    db.setNewUserToFalse()
                }

                this.setState({userName: name, offerCount: snapshot.offerCount})
            }
            this.setState({offerCount: snapshot.offerCount, metaLoading: false})
        }).catch((e) => console.log("error", e))

        db.getOfferTypeCount()
        .then(snapshot=>{
            this.setState({curApproved: snapshot.approvedCount, curPending: snapshot.pendingCount, curRejected: snapshot.rejectedCount})
        })
         .catch((e) => {
            // Dummy data in catch
            this.setState({curApproved: 0, curPending: 0, curRejected: 0})
            console.log("error", e)
         })

        // Disable check for updates button
        this.setState({checkForUpdatesClicked: true})
        if (!didLogin) {
            this.setState({loading: true})
        }

        var uid = db.getUserID()
        let cfDraft = 'https://us-central1-oaklas-user.cloudfunctions.net/checkDraftOrdersRefined?uid=' + uid
        let cfItems = 'https://us-central1-oaklas-user.cloudfunctions.net/checkItemStatus?uid=' + uid
        let cfCheckShopify = 'https://us-central1-oaklas-user.cloudfunctions.net/checkForFailedShopifyCall?uid=' + uid
        const urls = [cfDraft, cfItems, cfCheckShopify]
        var upOrDown = 0

        Promise.all(urls.map(url =>
            fetch(url).then(resp => resp.json())
        )).then(responses => {
            responses.forEach(response => {
                if (response && response.updateOfferCounter) {
                    // Update the state with the response values from CF
                    this.setState({offerCount: this.state.offerCount + response.updateOfferCounter})
                    upOrDown = upOrDown + response.updateOfferCounter
                }
            })

            // Absolute value to help with visual rendering in DOM
            this.setState({upAndDownValAbs: Math.abs(Number(upOrDown)), upAndDownVal: upOrDown})

            // Type defined by tab [0 === approved, 1 === pending .... 3]
            this.setType(this.state.type)
            if (!didLogin) {
                this.setState({openSuccess: true})
            }

            this.setState({checkForUpdatesClicked: false})
            return
        })
        .catch((e) => {
            this.setState({checkForUpdatesClicked: false})
            console.log(e)
        })

    }

    // Displays type
    setType(type) {
        if (type === 0) {
            this.getItemsPerStore("approved")
        } else if (type === 1) {
            this.getItemsPerStore("pending")
        } else if (type === 2) {
            this.getItemsPerStore("rejected")
        }
    }

    getItemsPerStore(whichType) {
        this.setState({stores: [], noItemsInAnyStore: false})
        db.getItems(whichType)
        .then(snapshot => {
            this.getStoreLogo(snapshot)
        })
        .catch((e) => console.log("error", e) )
    }

    // Gets logos for each store
    getStoreLogo(storesList) {
        let noItemsInAnyStore = true
        const promises = []
        const storesWithItems = []

        for (let store of storesList) {

            if (store.storeItems.length > 0) {
                noItemsInAnyStore = false
                promises.push(db.getStoreLogoFromDB(store.storeName))
                storesWithItems.push(store)
            }

        }

        Promise.all(promises.map(p => p.catch(() => undefined)))
        .then(responses => {
                const finalStoresList = []
                for (let i in storesWithItems) {
                    const store = storesWithItems[i]
                    let logo = ''
                    if (i < responses.length) {
                        logo = responses[i]
                    }
                    let text = store.storeName
                    let result = text.replace( /([A-Z])/g, " $1" )
                    const storeNotCamel = result.charAt(0).toUpperCase() + result.slice(1)
                    var item = {
                        name: store.storeName,
                        storeNotCamel: storeNotCamel,
                        logo: logo,
                        storeItems: store.storeItems
                    }
                    finalStoresList.push(item)

                }

                this.setState({ stores: finalStoresList, loading: false, noItemsInAnyStore: noItemsInAnyStore})
        })

    }

    // Displays Dialog
    whatIsOfferCount = () => {
        this.setState({ isNewUser: true });
    };

    // Display page updated snack
    handleClickSnackSuccess = () => {
        this.setState({ openSuccess: false });
    };

    // Type change handler
    handleChange = (event, type) => {
        this.setState({ type })
        this.setState({stores: [], loading: true})
        this.checkForUpdates(true)
    }

    changeName = () => {
        this.setState({ openName: true })
    }

    render () {
        const { classes, isMobile, curWidth } = this.props
        const { openSuccess, openName, type, userName, stores, loading,
                noItemsInAnyStore, isNewUser, offerCount, curPending, curApproved, curRejected, checkForUpdatesClicked } = this.state
        return (

            <div style={ isMobile ? {paddingTop: '70px'} : {paddingTop: '110px'} } className={classes.root}>

            <HelpModal openName={openName} offerCount={offerCount - curPending - curApproved} isNewUser={isNewUser} userName={userName} handleClose={didChangeName => {
                if (didChangeName) {
                    this.setState({userName: didChangeName})
                }
                this.setState({openName: false, isNewUser: false})}
            }/>

            <Grid container alignItems="center" spacing={24}>

            <Grid item xs={12} className={classes.itemGrid}>
            {!isMobile ?
                <Grid container alignItems="center" spacing={0}>
{/*
                <Grid item xs={3} className={classes.offerCounter}>
                    <Tooltip placement="bottom" title="What is this?">
                        <div className={classes.row} onClick={this.whatIsOfferCount}>
                            {curWidth < 630 ?
                                <div>
                                    <Avatar className={classNames(classes.avatar, classes.avatarSmall)}>
                                        {metaLoading ? (
                                            <CircularProgress className={classes.progress} style={{color: 'white'}} size={40} color="secondary"/>
                                        ) : offerCount === 0 ? (
                                            <div style={{color: 'white'}}> Out of offers <br/> email <br/> info@oaklas.com <br/> to get more</div>
                                        ) :
                                            <div> You can make <br/> <p className={classes.offerCountAvatar}> {offerCount - curPending - curApproved}</p> <br/> Offers </div>
                                        }
                                        </Avatar>
                                    { upAndDownVal > 0 ? (
                                    <div>
                                        <Typography style={{color: 'grey', fontSize: '15px'}} component="h2" variant="subheading">You've made a purchase!</Typography>
                                        <Typography style={{color: 'green'}} className={classes.newOffer} component="h2" variant="subheading"> <ArrowUpward /> {upAndDownValAbs} new offers available</Typography>
                                    </div>
                                    ) : upAndDownVal < 0 ? (
                                        <div className={classes.alignIcon}>
                                            <Typography style={{color: 'grey', fontSize: '15px'}} component="h2" variant="subheading">You didn't make a purchase</Typography>
                                            <Typography style={{color: 'red'}} className={classes.newOffer} component="h2" variant="subheading"> <ArrowDownward /> {upAndDownValAbs} less offers available</Typography>
                                        </div>
                                    ) : null }
                                </div>
                                :
                                <div>
                                    <Avatar className={classes.avatar} style={{background: '#f6f6f8'}}>
                                        {metaLoading ? (
                                            <CircularProgress className={classes.progress} style={{color: 'white'}} size={40} color="secondary"/>
                                        ) : offerCount === 0 ? (
                                            <div style={{color: 'white'}}> Out of offers <br/> email <br/> info@oaklas.com to get more</div>
                                        ) :
                                            <div> <p className={classes.offerCountAvatar}> {offerCount - curPending - curApproved}</p> </div>
                                        }
                                    </Avatar>
                                    <Avatar className={classes.avatarQuestionMark} style={{background: '#f6f6f8'}}>
                                        {metaLoading ? (
                                            <CircularProgress className={classes.progress} style={{color: 'white'}} size={40} color="secondary"/>
                                        ) : offerCount === 0 ? (
                                            <div style={{color: 'white'}}> Out of offers <br/> email <br/> info@oaklas.com to get more</div>
                                        ) :
                                            <div> <p className={classes.offerCountAvatar} style={{color: '#2a6bff', fontSize:'17px',}}> ?</p> </div>
                                        }
                                    </Avatar>
                                    {upAndDownVal > 0 ? (
                                        <div>
                                            <Typography style={{color: 'grey', fontSize: '15px'}} component="h2" variant="subheading">You've made a purchase!</Typography>
                                            <Typography style={{color: 'green'}} className={classes.newOffer} component="h2" variant="subheading"> <ArrowUpward /> {upAndDownValAbs} new offers available</Typography>
                                        </div>
                                    ) : upAndDownVal < 0 ? (
                                        <div>
                                            <Typography style={{color: 'grey', fontSize: '15px'}} component="h2" variant="subheading">You didn't make a purchase</Typography>
                                            <Typography style={{color: 'red'}} className={classes.newOffer} component="h2" variant="subheading"> <ArrowDownward /> {upAndDownValAbs} less offers available</Typography>
                                        </div>
                                    ) : null }
                                </div>
                            }
                        </div>
                    </Tooltip>
                </Grid> */}
                <Grid item xs={8}>
                    <Grid container alignItems="center" spacing={24} style={{textAlign: 'left'}}>
                        <Grid item xs={12}>
                            <Tooltip title="Change name" placement="bottom-start">
                                <Button className={classes.nameButton}>
                                    <Typography onClick={this.changeName} component="h2" variant="display1" style={{color: '#3e3e3e', fontWeight: '600'}} className={classes.userName}>{userName}</Typography>
                                </Button>
                            </Tooltip>
                            { curWidth > 630 ?
                                <Tooltip title="Check for Updates">
                                    <IconButton disabled={checkForUpdatesClicked} type='button' className={classes.buttonUpdate} onClick={() => this.checkForUpdates(false)}>
                                        <Update/>
                                    </IconButton>
                                </Tooltip>
                            : null }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.bidInfo} variant="subheading"><b> {curApproved} </b> Approved</Typography>
                            <Typography className={classes.bidInfo} variant="subheading"><b> {curPending} </b> Pending</Typography>
                            <Typography className={classes.bidInfo} variant="subheading"><b> {curRejected} </b> Rejected</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}/>
            </Grid>
        :
            <Grid container alignItems="center" spacing={0}>
                <Grid item xs={1}/>
                    {/* <Grid item xs={2} className={classes.offerCounter}>
                        <div className={classes.row} onClick={this.whatIsOfferCount}>
                            {metaLoading ? (
                                <CircularProgress className={classes.progress} style={{color: 'white'}} size={20} color="secondary"/>
                            ) :
                                <Avatar className={classes.avatarMobile}>{offerCount - curPending - curApproved}</Avatar>
                            }

                        </div>
                    </Grid> */}
                    <Grid item xs={9} style={{padding: '0px', textAlign: 'left'}}>
                        <Button className={classes.nameButtonMobile}>
                            <Typography onClick={this.changeName} variant="title" className={classes.userName}>{userName}</Typography>
                        </Button>
                        <IconButton disabled={checkForUpdatesClicked} type='button' className={classes.buttonUpdate} onClick={() => this.checkForUpdates(false)}>
                            <Update/>
                        </IconButton>
                    </Grid>
                </Grid>
        }
        </Grid>
        <Grid item xs={12} className={classes.itemGrid}>
            <div>
                {isMobile ?
                    <Grid container direction="row" alignItems="center" justify="space-around" spacing={0} className={classes.countObjects}>
                        <Grid item>
                            <Typography className={classes.bidInfoMobile} variant="body1"><b> {curApproved} </b> <br/> Approved</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.bidInfoMobile} variant="body1"><b> {curPending} </b> <br/> Pending</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.bidInfoMobile} variant="body1"><b> {curRejected} </b> <br/> Rejected</Typography>
                        </Grid>
                    </Grid>
                : null }
                <Tabs
                    value={this.state.type}
                    onChange={this.handleChange}
                    variant ="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab icon={<CheckCircle />} disabled={loading} className={classes.text} label="Approved" />
                    <Tab icon={<HelpIcon />} disabled={loading} className={classes.text} label="Pending" />
                    <Tab icon={<CancelIcon />} disabled={loading} className={classes.text} label="Rejected" />
                    {/* <Tab icon={<WatchLaterIcon />} disabled={loading} className={classes.text} label="Expired" /> */}
                </Tabs>
                </div>
                <div>
                    { _.size(stores) > 0 && !loading ? (
                        <div>
                            { !isMobile ?
                            <div>
                                {_.map(stores, (store, i) => (
                                    <Grid className={classes.storeRoot} item key={i}>
                                        <Store type={type} storeDetails={store}/>
                                    </Grid>
                                ))}
                            </div>
                            :
                            <Grid container justify='center'>
                                <Grid item xs={11}>
                                    <div>
                                        {_.map(stores, (store, i) => (
                                            <Grid className={classes.storeRoot} item key={i}>
                                                <Store type={type} storeDetails={store}/>
                                            </Grid>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                            }
                        </div>
                    ) : loading ? (
                        <div style={{paddingTop: '100px'}}>
                            <CircularProgress className={classes.progress} size={80} color="secondary"/>
                        </div>
                    ) : !loading && noItemsInAnyStore ? (
                        <div>
                            <Grid container justify='center'>
                                <Grid item xs={11} className={classes.divLogo}>
                                    { type === 0 ? (
                                        <div>
                                            <Typography className={classes.titleText} variant="h6">You have no approved offers</Typography>
                                            <Typography variant="subheading" className={classes.textParagraph} >Visit one of the retailers below to start making offers now</Typography>
                                        </div>
                                    ) : type === 1 ? (
                                        <div>
                                                <Typography className={classes.titleText} variant="h6">You have no pending offers!</Typography>
                                                <Typography variant="subheading" className={classes.textParagraph}>Visit our website to see what stores are available to make an offer on</Typography>
                                        </div>
                                    ) : type === 2 ? (
                                        <div>
                                            <Typography className={classes.titleText} variant="h6">You have no rejected offers!</Typography>
                                            <Typography variant="subheading" className={classes.textParagraph}> Congratulations and keep making offers</Typography>
                                        </div>
                                    )  : null }
                                </Grid>
                            </Grid>
                        </div>
                    ) : null }
                </div>
            </Grid>
        </Grid>

        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={openSuccess}
            onClose={this.handleClickSnackSuccess}
            autoHideDuration={3000}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Page Updated</span>}
        />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        {/*{isMobile?
        <p style={{textAlign: 'left', marginLeft: '15px'}}>Store suggestions</p>
        :
        <p style={{textAlign: 'left'}}>Store suggestions</p>
        } */}
        <Grid container direction='row' style={{width: '100%', alignSelf: 'stretch', flexGrow: 1}}>
            {/*{isMobile ?
            <Grid item xs={12} sm={6}>
                    <Card className={classes.card} style={{height: '100%', boxShadow: 'none', borderRadius: '0px', background: "#f6f6f8", marginLeft: '15px'}}>
                    <CardMedia
                    className={classes.media}
                    image="https://s3-ap-southeast-2.amazonaws.com/oaklas/stores/theTimekeeper/indexFeatureImage.jpg"
                    title="THE TIMEKEEPER"
                    />
                    <p style={{textAlign: 'center'}}>THE TIMEKEEPER</p>
                </Card>
            </Grid>
            :
            <Grid item xs={12} sm={6} style={{maxWidth: '295px'}}>
                    <Card className={classes.card} style={{height: '100%', boxShadow: 'none', borderRadius: '0px', background: "#f6f6f8", maxWidth: '280px'}}>
                    <CardMedia
                    className={classes.media}
                    style={{width: '280px', height: '220px'}}
                    image="https://s3-ap-southeast-2.amazonaws.com/oaklas/stores/theTimekeeper/indexFeatureImage.jpg"
                    title="THE TIMEKEEPER"
                    />
                    <p style={{textAlign: 'center'}}>THE TIMEKEEPER</p>
                </Card>
            </Grid>
        }*/}
            {isMobile ?
            <Grid item xs={12}>
                <Card className={classes.card} style={{height: '100%',flexGrow:'1', boxShadow: 'none', background: '#F6F6F8', marginLeft: '15px', marginTop: '15px', minHeight: '300px',}}>
                    <CardContent>
                        <Typography component="p" style={{textAlign: 'center', color: '#3e3e3e', marginTop: '10px'}}>
                        Stay up to date
                        </Typography>
                        <Typography component="p" style={{textAlign: 'center', color: '#7f7f7f', marginBottom: '60px'}}>
                        when new retailers are added
                        </Typography>
                    </CardContent>
                    <CardActions style={{justifyContent: 'center'}} className={classes.actions} disableActionSpacing>
                        <IconButton style={{color: "#2a6bff",marginLeft: '20px' , marginRight: '20px', background: 'white'}}
                            href = "https://instagram.com/oaklas.official"
                            target = "_blank"
                        >
                            <FontAwesomeIcon icon={faInstagram}/>
                        </IconButton>
                        <IconButton style={{color: "#2a6bff",marginLeft: '20px' , marginRight: '20px', background: 'white'}}
                            href = "https://facebook.com/oaklas.official"
                            target = "_blank"
                        >
                            <FontAwesomeIcon icon={faFacebookF}/>
                        </IconButton>
                        <IconButton style={{color: "#2a6bff",marginLeft: '20px' , marginRight: '20px', background: 'white'}}
                            href = "https://twitter.com/oaklas_official"
                            target = "_blank"
                        >
                            <FontAwesomeIcon icon={faTwitter}/>
                        </IconButton>
                    </CardActions>
                </Card>
            </Grid>
            :
            <Grid item xs={12}>
                <Card style={{height: '100%', flexGrow:'1', boxShadow: 'none', background: '#F6F6F8', paddingBottom: '30px'}}>
                    <CardContent>
                        <Typography component="p" style={{textAlign: 'center', color: '#3e3e3e', marginTop: '10px'}}>
                        Stay up to date
                        </Typography>
                        <Typography component="p" style={{textAlign: 'center', color: '#7f7f7f', marginBottom: '60px'}}>
                        when new retailers are added
                        </Typography>
                    </CardContent>
                    <CardActions style={{justifyContent: 'center'}} className={classes.actions} disableActionSpacing>
                        <IconButton style={{color: "#2a6bff", marginLeft: '20px' , marginRight: '20px', background: 'white'}}
                            href = "https://instagram.com/oaklas.official"
                            target = "_blank"
                        >
                            <FontAwesomeIcon icon={faInstagram}/>
                        </IconButton>
                        <IconButton style={{color: "#2a6bff", marginLeft: '20px' ,marginRight: '20px', background: 'white'}}
                            href = "https://facebook.com/oaklas.official"
                            target = "_blank"
                        >
                            <FontAwesomeIcon icon={faFacebookF}/>
                        </IconButton>
                        <IconButton style={{color: "#2a6bff", marginLeft: '20px' ,marginRight: '20px', background: 'white'}}
                            href = "https://twitter.com/oaklas_official"
                            target = "_blank"
                        >
                            <FontAwesomeIcon icon={faTwitter}/>
                        </IconButton>
                    </CardActions>
                </Card>
            </Grid>
            }
            {/* <Grid item xs='3'>
                    <Card className={classes.card} style={{height: '100%', boxShadow: 'none', background: "#F6F6F8"}}>
                    <CardContent>
                        <Typography component="p" style={{textAlign: 'center', color: '#3e3e3e'}}>
                        Suggest a store
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.actions} style={{justifyContent: 'center'}} disableActionSpacing>
                        <IconButton style={{color: "#2a6bff"}}
                            onClick={suggestStore}
                        >
                            <Add/>
                        </IconButton>
                    </CardActions>
                </Card>
            </Grid> */}
        </Grid>

        </div>
    )}
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 480,
    curWidth: width
})

export default withSizes(mapSizesToProps)(withStyles(styles)(Home))
