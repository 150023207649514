import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button'
import Create from '@material-ui/icons/Create';
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
    cardBody: {
        padding: '10px',
        backgroundColor: '#F6F6F8',
    },
    cardText: {
        color: theme.palette.common.black,
        marginTop: 'none',
        textAlign: 'center'
    },
    checkAndClock: {
        background: 'black',
        opacity: '0.8',
        textAlign: 'center',
        color: 'white'
    },
    checkOnly: {
        textAlign: 'center',
        color: '#2A6BFF'
    },
    NunitoBold: {
        fontWeight: '400',
        letterSpacing: '1px',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    NunitoLight: {
        fontFamily: 'Nunito Sans, sans-serif',
        fontWeight: '400'
    },
    button: {
        margin: theme.spacing.unit,
        '&:hover': {
            background: `linear-gradient(125deg, #2A6BFF 50%, #5A89FF 50%)`,
        }
    },
    buttonShopping: {
        margin: theme.spacing.unit,
        background: `linear-gradient(125deg, #2A6BFF 50%, #5A89FF 50%)`,
    }
})

class ItemDesktop extends React.Component {

    state = {
        isHovering: false,
        isSelected: true,
        dateState: '',
        isItemExpired: false
    }

    static propTypes = {
        imageSrc: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        listPrice: PropTypes.number,
        bidPrice: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        type: PropTypes.number.isRequired,
        date: PropTypes.number,
        productURL: PropTypes.string.isRequired,
        currency: PropTypes.string,
        currencySign: PropTypes.string,
        variantName: PropTypes.string
    }

    componentDidMount() {
        if (this.props.variantName !== "N/A") {
            this.setState({variantName: this.props.variantName})
        }
        this.setState({dateState: this.props.date})
    }

    handleChange = name => event => {
        const { id, onToggleItem } = this.props;
        onToggleItem(id);
        this.setState({ [name]: event.target.checked });
            this.setState({openSnack: true})
    };

    onItemExpiredStatus = expired => {
        console.log(expired)
        this.setState({ isItemExpired: expired })
        if (expired) {
            this.setState({ isSelected: false })
        }
    };

    handleCloseSnack = () => {
         this.setState({ openSnack: false });
    };

  render () {
      const { imageSrc, name, bidPrice, listPrice, classes, type, productURL, currencySign } = this.props
      const { openSnack, isHovering, isSelected, variantName } = this.state

    return (
        <div
          onMouseEnter={() => this.setState({isHovering: true})}
          onMouseLeave={() => this.setState({isHovering: false})}
        >
          <Paper style={{boxShadow: 'none'}}>
        <div>
            <Grid
              container
              spacing={0}
              direction="column"
            >
              <Grid item xs={12} style={{background: `url(${imageSrc}) no-repeat center center`, height: '265px', backgroundSize: 'cover'}}>
                  <div>
                      <Grid className={classes.checkOnly} container justify="space-between" direction="row" alignItems="center">
                      { (type === 1 || type === 2 || type === 3) && isHovering ?
                          <Grid item>
                              {type === 1 ?
                                  <Tooltip title="Update your current offer">
                                      <Button variant="fab" mini color="primary" aria-label="Add" className={classes.button} href={productURL} target="_blank">
                                        <Create />
                                      </Button>
                                  </Tooltip>
                              : type === 2 ?
                              <Tooltip title="Make another offer">
                                  <Button variant="fab" mini color="primary" aria-label="Add" className={classes.button} href={productURL} target="_blank">
                                    <Create />
                                  </Button>
                              </Tooltip>
                              : null }
                          </Grid>
                      : null }
                      </Grid>
                  </div>
              </Grid>
              <Grid item xs={12} className={classes.cardBody}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                      <Typography variant="headline" component="h2" className={classNames(classes.cardText, classes.NunitoBold)}>
                            {name} {variantName ? <div style={{display: "inline"}}> &middot; </div> : null} {variantName}
                      </Typography>
                  </Grid>
                  <Grid item xs={12} className={classNames(classes.cardText, classes.NunitoLight)}>
                      <Typography variant="body1" gutterBottom style={{marginTop: '0px', fontFamily: 'Nunito Sans, sans-serif'}}>
                          List Price <b> {currencySign}{listPrice} </b> | Your Offer <b> {currencySign}{bidPrice}</b>
                      </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </div>


        </Paper>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={openSnack}
          onClose={this.handleCloseSnack}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={isSelected ? <span id="message-id">Item added to checkout</span> : <span id="message-id">Item removed from checkout</span>}
        />

      </div>

    )
  }
}

export default withStyles(styles)(ItemDesktop)
