import { userDB, auth, retailerStorage, retailerDB } from './firebase';

export function getCurrentUser() {
    return new Promise(function (resolve, reject) {
        return resolve(auth.currentUser)
    })
}

export function getUserID() {
    return auth.currentUser.uid
}

export function getUserMeta() {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid
        userDB.ref('/users/' + userID + "/meta").once('value', snapshot => {
            return resolve(snapshot.val())
        })
        .catch((e) => {
            return reject()
        })
    })
}

export function setNewUserToFalse() {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid
        userDB.ref('/users/' + userID + '/meta/isNewUser').set(false)
        .then( () => { return resolve()})
        .catch((e) => console.log(e))
    })
}


export function getUserName() {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid

        userDB.ref('/users/' + userID + "/meta").once('value')
        .then(metdata => {
            const firstName = metdata.child('firstName').val()
            const lastName = metdata.child('lastName').val()
            const name = firstName + " " + lastName
            return resolve(name)
        })
        .catch((e) => {
            return reject()
        })
    })
}


export function updateName(firstName, lastName) {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
        lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
        userDB.ref('/users/' + userID + "/meta/").update({
            firstName: firstName,
            lastName: lastName,
        })
        .then(() => {
            return resolve(true)
        })
        .catch((e) => {
            return reject()
        })
    })
}

export function getBidStats(whichType) {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid
        var counter = 0
        userDB.ref('/users/' + userID + `/stores`).once('value')
        .then(snap => {
            snap.forEach(x => {
                const temp = x.child(`/${whichType}`).numChildren()
                counter = counter + temp
            })
            return resolve(counter)
        })
        .catch((e) => {
            return reject()
        })
    })
}

export function getOfferCount() {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid
        userDB.ref('/users/' + userID + `/meta/offerCount`).once('value')
        .then(snap => {
            return resolve(snap.val())
        })
        .catch((e) => {
            return reject()
        })
    })
}

export function getShopNames() {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid
        var stores = []
        userDB.ref('/users/' + userID + "/stores").once('value', snap => {
            snap.forEach(storeNames => {
                stores.push(storeNames.key)
            })
            return resolve(stores)
        })
    })
}

export function getItems(type) {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid
        userDB.ref('/users/' + userID + "/stores").once('value', snap => {
            let stores = []
            snap.forEach(store => {
                let itemList = []
                store.child(type).forEach(item => {
                    itemList.push(item.val())
                })
                stores.push({storeName: store.key, storeItems: itemList})
            })

            return resolve(stores)
        }, (error) => {
            reject(error);
        })
    })
}

export function getDeletedItems(storeName) {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid
        userDB.ref('/users/' + userID + '/stores/' + storeName + "/rejected").once('value', snap => {
            let itemList = []
            snap.forEach(item => {
                itemList.push(item.val())
            })
            return resolve(itemList)
        }, (error) => {
            reject(error);
        })
    })
}

export function getStoreURL(storeName) {
    return new Promise(function (resolve, reject) {
        retailerDB.ref('/stores/' + storeName + "/meta/url").once("value").then(x => {
                return resolve(x.val())
        }, (e) => {
            reject(e)
        })

    })
}

export function getStoreLogoFromDB(storeName) {
    return new Promise(function (resolve, reject) {
        const image = `storeLogos/${storeName}Logo.png`
        const pathReference = retailerStorage.ref(`${image}`);
        pathReference.getDownloadURL().then(url => {
            return resolve(url)
        })
        .catch((e) => {
            return resolve("empty")
        })
    })
}

export function getOfferTypeCount() {
    return new Promise(function (resolve, reject) {
        var userID = auth.currentUser.uid
        var pendingCount = 0;
        var approvedCount = 0;
        var rejectedCount = 0;
        userDB.ref('/users/' + userID + "/stores").once('value', snapshot => {
            snapshot.forEach(storeSnap=>{
                storeSnap.forEach(sectionSnap=>{
                    if (sectionSnap.key==='pending'){
                        pendingCount += sectionSnap.numChildren();
                    }
                    else if(sectionSnap.key==='rejected'){
                        rejectedCount += sectionSnap.numChildren();
                    }
                    else if (sectionSnap.key==='approved'){
                        approvedCount += sectionSnap.numChildren();
                    }
                })
            })
            var count = {'pendingCount': pendingCount, 'approvedCount': approvedCount, 'rejectedCount': rejectedCount}
            return resolve(count);
        })
        .catch((e) => {
            return reject()
        })
    })
}
